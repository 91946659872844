<template>
  <div class="proxy-page">

    <img src="https://homepage-oss.oss-cn-beijing.aliyuncs.com/h5/proxy.png?x-oss-process=style/half_q" style="width:100vw" alt="">
    <img src="https://homepage-oss.oss-cn-beijing.aliyuncs.com/h5/proxypage-2.png?x-oss-process=style/half_q" style="width:100vw" alt="">

    <div style="position:fixed;top:3vh;right:6px">
      <!-- <cube-button>申请成为合伙人</cube-button> -->
      <van-button
        size="small"
        color="#f2826a"
        type="primary"
        @click="apply()"
      >
        申请成为合作伙伴
      </van-button>
    </div>
    <van-popup v-model="show" :style="{width:'80%',boxShadow: '0 4px 8px #ebedf0'}">
      <h3 style="margin:5vw 20px;text-align:center">
        合作申请
        <!-- <van-tag color="#f2826a" @click="autoDemoAccount()">申请模拟号体验</van-tag> -->
      </h3>
      <van-row style="">
        <van-col span="24">
          <van-field
            v-model="phone"
            type="tel"
            label="手机号(+86)"
            clearable
            required
            placeholder="请填写手机号"
            maxlength="11"
          >
            <!-- <van-icon slot="right-icon" name="arrow-down" @click="selectSecurities()" /> -->
          </van-field>
          <!-- {{ form }} -->
          <van-field
            v-model="fullname"
            type="text"
            label="姓 名"
            clearable
            required
            placeholder="请填写您的真实姓名"
          >
            <!-- <van-icon slot="left-icon" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" /> -->
          </van-field>
          <van-field
            v-model="inviteByCode"
            type="text"
            label="推荐码"
            clearable
            placeholder="请输入推荐人邀请码"
          />

        </van-col>
      </van-row>
      <van-row>
        <van-button style="width:50%" @click="show = false">取 消</van-button>
        <van-button style="width:50%" color="#f2826a" @click="submit()">提交申请</van-button>

      </van-row>
    </van-popup>

  </div>
</template>
<script>
import {
	Dialog,
	Field,
	Popup,
	Row,
	Col
} from 'vant'
import {
	agentApply
} from '@/api/theApi'
export default {

	components: {
		[Row.name]: Row,
		[Col.name]: Col,
		[Popup.name]: Popup,
		[Dialog.Component.name]: Dialog.Component,
		[Field.name]: Field

	},
	data() {
		return {
			show: false,
			phone: '',
			fullname: '',
			inviteByCode: ''
		}
	},
	mounted() {
	},
	methods: {
		apply() {
			this.show = true
		},
		submit() {
			const phoneNumber = this.phone
			const fullname = this.fullname
			const inviteByCode = this.inviteByCode
			agentApply(phoneNumber, fullname, inviteByCode).then(
				res => {
					Dialog.alert({ message: '提交申请成功，我们的工作人员将在2个工作日内联系您' })
					this.show = false
				}
			).catch()
		}
	}
}
</script>
<style lang="less" scoped>
.proxy-apge{
  width:100vw;
  // height: 100vh;
  position: relative;

}

</style>
